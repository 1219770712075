import React from 'react';
import { graphql } from 'gatsby';

import { Hero } from '../components/Hero';
import PageLayout from '../components/PageLayout';
import SEO from '../components/seo';
import { Badges } from '../components/Badges';
import { Container, Datenschutz, SectionContainer } from '../styles/datenschutz';

const Security = ({ data: { Postbote } }) => (
  <PageLayout className="bg-white">
    <SEO title="Datenschutzerklärung" />
    <Hero image={Postbote} title="Datenschutzerklärung">
      <div className="font-display font-xl tracking-wide">
        Jetzt die DropFriends-App downloaden.
      </div>
      <Badges />
    </Hero>
    <SectionContainer>
      <Container>
        <Datenschutz cursor="pointer" href="/Datenschutzerklärung_03.12.2019.pdf">
          Hier die Datenschutzerklärung zum Download
        </Datenschutz>
      </Container>
    </SectionContainer>
  </PageLayout>
);

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "postbote.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default Security;
